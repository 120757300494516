.radio-item {
  @apply inline-block relative px-[6px] ml-2;
}

.radio-item input[type='radio'] {
  @apply hidden;
}

.radio-item label {
  @apply text-base cursor-pointer;
}

.radio-item label:before {
  @apply inline-block relative top-[2px] right-[6px] ml-1 w-4 h-4 rounded-full border border-primary bg-transparent;
  content: ' ';
}

.radio-item input[type='radio']:checked + label:after {
  @apply rounded-full w-[10px] h-[10px] absolute top-[6px] left-[7px] block bg-primary;
  content: ' ';
}
