@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('./navbar.css');
@import url('./banner.css');
@import url('./portfolio.css');
@import url('./contact.css');
@import url('./radio.css');
@import url('./slick.css');

.content-wrap {
  @apply flex flex-col overflow-hidden h-screen;
}

.section-content {
  @apply h-full mt-16 pb-8 tablet:mt-[theme('height.navbar')] overflow-y-auto;
  background-image: url('../assets/images/background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  @apply max-w-[1200px] mx-auto px-4 xl:px-8 !important;
}

.header {
  @apply text-header md:text-3xl;
}

.card {
  @apply mt-6 p-5 xl:py-8 xl:px-14 bg-background/80 rounded-3xl xl:rounded-[38px];
}
