@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./styles/main.css');
/* @import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700&display=swap');

*,
html {
  scroll-behavior: smooth !important;
}

body {
  @apply bg-background text-black m-0 text-sm scroll-smooth overflow-hidden max-h-screen;
  /* font-family: 'Anuphan', sans-serif; */
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @apply h-full overflow-y-auto;
}
