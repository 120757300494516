.card-thumbnail img {
  @apply transition-transform duration-500 grayscale;
}

.card-thumbnail:hover img {
  @apply xl:scale-110 grayscale-0;
}

.card-thumbnail .thumbnail {
  @apply relative pt-[133.33%];
}

.card-thumbnail .thumbnail img {
  @apply absolute inset-0 w-full h-full object-cover;
}
