.site-menu-blog:hover > div {
  @apply xl:fill-primary xl:text-primary transition-colors duration-200;
}

html#facebook body > div > div > div > div {
  @apply bg-white !important;
}

html#facebook body > div > div > div > div svg path {
  @apply fill-black transition-colors duration-200 !important;
}

html#facebook:hover body > div > div > div > div svg path {
  @apply fill-primary !important;
}
